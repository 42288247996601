/****************************** components ******************************/
@import "./components/mixin";
@import "./components/default";
@import "./components/normalize";
@import "./components/reset";
@import "./components/grid";
//@import "./components/case";//例子
/****************************** style ******************************/

body,
button,
input,
select,
textarea {
    font: 12px/1.4 "\5FAE\8F6F\96C5\9ED1", "arial";
    color: #5a5a5a;
    font-size: 12px;
}

body {
    max-width: 1920px;
    margin: 0 auto;
}
.center {
    width: 1200px;
    margin: 0 auto;
}


.header {
    width: 100%;
    position: relative;
    .head_top {
        width: 100%;
        .text {
            position: relative;
        }
        .tel {
            width: 120px;
            position: absolute;
            right: 0;
            top: 0;
            padding-top: 45px;
            text-align: right;
            a {
                width: 30px;
                height: 30px;
                @include inline-block();
                background: #333;
                border-radius: 50%;
                margin-left: 15px;
                img {
                    height: 15px;
                    margin: 7px auto;
                    display: block;
                }
                &:hover {
                    background: #243b8a;
                }
            }
        }
        .logo {
            float: left;
            position: absolute;
            left: 0;
            top: 0;
            width: 17%;
            a {
                img {
                    display: block;
                    max-width: 100%;
                    margin-top: 32px;
                }
            }
        }
        .nav {
            width: 100%;
            padding-left: 20%;
            padding-right: 140px;
            float: right;
            li {
                width: 16.66%;
                float: left;
                position: relative;
                height: 120px;
                padding-top: 39px;
                padding-left: 3.5%;
                >a {
                    color: #333;
                    font-size: 18px;
                    display: block;
                    line-height: 100%;
                    i {
                        display: block;
                        font-style: normal;
                        font-size: 12px;
                        color: #999;
                        margin-top: 5px;
                        font-family: arial;
                    }
                }
                .v_list {
                    position: absolute;
                    top: 120px;
                    left: 0;
                    width: 150%;
                    overflow: hidden;
                    background: #fff;
                    z-index: 99;
                    display: none;
                    a {
                        line-height: 37px;
                        display: block;
                        font-size: 14px;
                        color: #333;
                        padding: 0 30px;
                        &:hover {
                            background: #243b8a;
                            color: #fff;
                        }
                    }
                }
                &:first-child {
                    padding-left: 5.6%;
                }
                &:hover {
                    background: #243b8a;
                    >a {
                        color: #fff;
                        i {
                            color: #fff;
                        }
                    }
                }
            }
        }
    }
}

.m_header {
    width: 100%;
    overflow: hidden;
    display: none;
    .m_head_content {
        height: 60px;
        width: 100%;
        background: #fff;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 99;
        box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.5);
        .m_head_logo {
            height: 60px;
            float: left;
            margin-left: .05rem;
            img {
                max-height: 100%;
                float: left;
                height: 40px;
                margin-top:10px;
            }
        }
        .menu {
            float: right;
            width: 60px;
            height: 60px;
            i {
                color: #243b8a;
                font-size: 20px;
                text-align: center;
                line-height: 60px;
                display: block;
            }
        }
    }   
}

.app_menu {
    width: 100%;
    position: fixed;
    height: 100%;
    top: 60px;
    right: 0;
    z-index: 99;
    display: none;
    background: rgba(0, 0, 0, .5);
    ul {
        background: rgba(0, 0, 0,1);
        li {
            width: 100%;
            border-bottom: 1px solid #444;
            >a {
                width: 50%;
                display: block;
                span {
                    font-size: 14px;
                    width: 100%;
                    height: 42px;
                    line-height: 42px;
                    color: #fff;
                    display: block;
                    padding-left: 18px;
                    font-weight: bold;
                }
            }
            &.on {
                background: url(../img/bottom.png) no-repeat 95% 15px;
                background-size: 16px;
            }
        }
        .v_list {
            width: 100%;
            display: none;
            border-top: 1px dashed #444;
            a {
                display: block;
                font-size: 14px;
                width: 100%;
                line-height: 36px;
                color: #fff;
                display: block;
                padding-left: .4rem;
                border-bottom: 1px dashed #444;
                float: none;
            }
        }
    }
}
.m_banner {
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    display: none;
    margin-top: 60px;
    .mbanner {
        width: 100%;
        position: relative;
        ul {
            li {
                width: 100%;
                line-height: 0;
                img {
                    width: 100%;
                    height: auto;
                }
            }
        }
    }
}

.banner_btn {
    width: 100%;
    text-align: center;
    position: absolute;
    z-index: 9;
    bottom: 5px;
    overflow: hidden;
    a {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        display: inline-block;
        text-indent: -999px;
        overflow: hidden;
        margin: 0 3px;
        overflow: hidden;
        cursor: pointer;
        background: #f9c9d3;
        opacity: .4;
        margin: 0 5px;
        border: solid 2px #fff;
        &.in {
            background: #243b8a;
            opacity: 1;
        }

    }
}

.pc_banner {
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    line-height: 0;
    .pcbanner {
        width: 100%;
        position: relative;
    }
    .swiper-slide {
        width: 100%;
        background: #000;
        img {
            width: 100%;
            display: block;
        }
    }
    .banner_pagination  {
        position: absolute;
        width: 100%;
        left: 0;
        z-index: 13;
        text-align: center;
        padding-right: 5px;
        span {
            width: 40px;
            height: 4px;
            border-radius: 0;
            display: inline-block;
            text-align: center;
            background: none;
            opacity: 1;
            cursor: pointer;
            margin: 0 3px;
            background: rgba(255,255,255,.5);
            border: none;
            outline: 0;
            &.swiper-pagination-bullet-active {
                background: #fff;
                color: #fff;
            }
        }
    }
    .banner_prev {
        position: absolute;
        top: 44%;
        left: 10px;
        z-index: 12;
        width: 40px;
        height: 40px;
        background: url(../img/ble.png) no-repeat center;
        outline: 0;
        cursor: pointer;
    }
    .banner_next {
        position: absolute;
        top: 44%;
        right: 10px;
        z-index: 12;
        width: 40px;
        height: 40px;
        background: url(../img/bri.png) no-repeat center;
        outline: 0;
        cursor: pointer;
    }
}



.main {
    width: 100%;
    overflow: hidden;
}


.box_tit {
    span {
        font-size: 36px;
        text-align: center;
        color: #333;
        display: block;
        strong {
            i {
                font-style: normal;
                padding: 0 5px;
            }
        }
    }
    p {
        font-size: 18px;
        color: #333;
        display: block;
        text-align: center;
        margin-top: 6px;
    }
}
.i_box1 {
    background: #eee;
    padding-top: 85px;
    padding-bottom: 40px;
    .box {
        width: 100%;
        margin-top: 60px;
    }
}
.i_box1_list_m {
    display: none;
}

.more {
    margin: 0 auto;
    a {
        span {
            width: 160px;
            height: 42px;
            color: #fff;
            display: block;
            text-align: center;
            line-height: 42px;
            font-size: 16px;
            background: #243b8a;
            transition: .5s;
            &:hover {
                padding-left: 10px;
                transition: .5s;
            }
        }
    }
}

.i_box2 {
    width: 100%;
    padding-top: 30px;
    padding-bottom: 180px;
    background: url(../img/bg1.png) no-repeat bottom center #f7f7f7;
    .center {
        width: 1400px;
    }
    .box_tit {
        span {
            text-align: left;
            strong {
                color: #243b8a;
            }
        }
        p {
            text-align: left;
        }
        em {
            width: 50px;
            height: 4px;
            background: #243b8a;
            display: block;
            font-style: normal;
            margin-top: 10px;
        }
    }
    .box {
        margin-top: 50px;
        .fl {
            width: 40%;
            padding-top: 2%;
            .text {
                margin-top: 30px;
                p {
                    font-size: 18px;
                    color: #333;
                    display: block;
                    line-height: 180%;
                }
                .more {
                    margin: 0;
                    margin-top: 60px;
                }
            }
        }
        .fr {
            width: 50%;
            img {
                display: block;
                max-width: 100%;
            }
        }
    }
}


.i_box3 {
    width: 100%;
    padding-top: 80px;
    padding-bottom: 70px;
    background: url(../img/bg.jpg) no-repeat top center;
    .box_tit {
        span {
            color: #fff;
        }
        p {
            color: #fff;
        }
    }
    .center {
        width: 1400px;
        overflow: hidden;
        padding: 0 100px;
        position: relative;
    }
    .box {
        margin-top: 60px;
        .box3list {
            width: 100%;
            overflow: hidden;
            background: url(../img/bg.png) no-repeat center 60px;
            .swiper-slide {
                padding: 0 20px;
                .text {
                    width: 100%;
                    position: relative;
                }
                .pic {
                    width: 100%;
                    img {
                        display: block;
                        max-width: 100%;
                    }
                }
                .text1 {
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 100%;
                    height: 140px;
                    background: #fff;
                    display: block;
                    transition: .8s;
                    i {
                        width: 50px;
                        height: 50px;
                        background: #243b8a;
                        font-style: normal;
                        font-size: 24px;
                        color: #fff;
                        text-align: center;
                        line-height: 50px;
                        border-radius: 50%;
                        margin: 0 auto;
                        display: block;
                        font-weight: bold;
                        margin-top: -25px;
                        box-shadow: 0px 1px 10px 1px #ccc;
                    }
                    span {
                        display: block;
                        text-align: center;
                        font-size: 24px;
                        color: #333;
                        font-weight: bold;
                        margin-top: 20px;
                    }
                    p {
                        font-size: 14px;
                        color: #999;
                        display: block;
                        text-align: center;
                    }
                }
                .text2 {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    left: 0;
                    top: 100%;
                    background: rgba(0,0,0,.5);
                    transition: .8s;
                    h4 {
                        font-size: 24px;
                        color: #fff;
                        text-align: center;
                        margin-top: 30px;
                    }
                    i {
                        font-style: normal;
                        width: 1px;
                        height: 20px;
                        background: #fff;
                        display: block;
                        margin: 12px auto;
                    }
                    span {
                        font-size: 24px;
                        color: #fff;
                        display: block;
                        text-align: center;
                        font-weight: bold;
                    }
                    p {
                        font-size: 14px;
                        color: #999;
                        display: block;
                        text-align: center;
                    }
                    ul {
                        padding: 0 30px;
                        height: 150px;
                        margin-top: 40px;
                        li {
                            width: 100%;
                            a {
                                font-size: 18px;
                                color: #999;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                overflow: hidden;
                                display: block;
                                line-height: 30px;
                                &:hover {
                                    color: #fff;
                                    text-decoration: underline;
                                }
                            }
                        }
                    }
                    em {
                        height: 42px;
                        height: 42px;
                        color: #fff;
                        display: block;
                        text-align: center;
                        line-height: 42px;
                        font-size: 16px;
                        background: #243b8a;
                        margin-top: 60px;
                        font-style: normal;
                        margin: 0 30px;
                        margin-top: 20px;
                    }
                    small {
                        font-size: 16px;
                        color: #999;
                        display: block;
                        text-align: center;
                        margin-top: 15px;
                    }
                }
                &:hover {
                    .text1 {
                        bottom: 500px;
                        transition: .8s;
                    }
                    .text2 {
                        top: 0;
                        transition: .8s;
                    }
                }
            }
            .prev {
                position: absolute;
                top: 44%;
                left: 10px;
                z-index: 12;
                width: 75px;
                height: 200px;
                background: url(../img/ple.png) no-repeat center rgba(36,59,138,.5);
                outline: 0;
                cursor: pointer;
                &:hover {
                    background: url(../img/ple.png) no-repeat center #243b8a;
                }
            }
            .next {
                position: absolute;
                top: 44%;
                right: 10px;
                z-index: 12;
                width: 75px;
                height: 200px;
                background: url(../img/pri.png) no-repeat center rgba(36,59,138,.5);
                outline: 0;
                cursor: pointer;
                &:hover {
                    background: url(../img/pri.png) no-repeat center #243b8a;
                }
            }
        }
    }
}


.i_box4 {
    width: 100%;
    padding: 80px 0;
    overflow: hidden;
    .box4list {
        margin-top: 60px;
        position: relative;
        .swiper-slide {
            width: 1400px;
            transform: scale(1,.8);
            overflow: hidden;
            position: relative;
            .pic {
                width: 100%;
                img {
                    display: block;
                    max-width: 100%;
                }
            }
            .text {
                width: 50%;
                background: #243b8a;
                position: absolute;
                right: 0;
                bottom: 0;
                padding: 40px 50px;
                padding-bottom: 100px;
                display: none;
                a {
                    font-size: 24px;
                    color: #fff;
                    display: block;
                    font-weight: bold;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                }
                p {
                    font-size: 18px;
                    color: #c6cfef;
                    display: block;
                    line-height: 180%;
                    margin-top: 20px;
                }
            }
        }
        .prev {
            position: absolute;
            left: 52.7%;
            bottom: 40px;
            z-index: 12;
            width: 110px;
            height: 35px;
            background: url(../img/le1.png) no-repeat center rgba(36,59,138,.5);
            outline: 0;
            cursor: pointer;
        }
        .next {
            position: absolute;
            left: 60%;
            bottom: 40px;
            z-index: 12;
            width: 110px;
            height: 35px;
            background: url(../img/ri1.png) no-repeat center rgba(36,59,138,.5);
            outline: 0;
            cursor: pointer;
        }
        .swiper-slide-active {
            transform: scale(1);
            .text {
                display: block;
            }
        }
    }
    .more {
        margin-top: 60px;
        a {
            span {
                margin: 0 auto;
            }
        }
    }
}

.i_box5 {
    width: 100%;
    padding: 80px 0;
    background: #f7f7f7;
    .center {
        width: 1400px;
    }
    .box {
        margin-top: 60px;
        .fl {
            width: 49%;
            background: #fff;
            .pic {
                img {
                    display: block;
                    max-width: 100%;
                    position: relative;
                    z-index: 9;
                }
            }
            .new_time {
                margin-top: -40px;
                position: relative;
                z-index: 10;
            }
            .text {
                padding: 30px 10px 28px 30px;
                a {
                    font-size: 22px;
                    color: #333;
                    display: block;
                    font-weight: bold;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                }
                p {
                    font-size: 18px;
                    color: #999;
                    display: block;
                    line-height: 160%;
                    margin-top: 20px;
                }
            }
        }
        .fr {
            width: 49%;
            li {
                width: 100%;
                padding: 20px;
                background: #fff;
                margin-bottom: 23px;
                position: relative;
                .libg {
                    height: 3px;
                    background: #243b8a;
                    width: 0;
                    transition: .8s;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                }
                .item {
                    position: relative;
                    .new_time {
                        position: absolute;
                        left: 0;
                        top: 0;
                    }
                    .text {
                        width: 100%;
                        min-height: 80px;
                        padding-left: 100px;
                        a {
                            font-size: 18px;
                            color: #333;
                            font-weight: bold;
                            display: block;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            overflow: hidden;
                        }
                        p {
                            font-size: 16px;
                            color: #999;
                            display: block;
                            line-height: 24px;
                            margin-top: 5px;
                        }
                    }
                }
                &:hover {
                    box-shadow: 0px 1px 10px 1px #ccc;
                    .libg {
                        width: 100%;
                        transition: .8s;
                    }
                }
            }
        }
    }
    .more {
        margin-top: 50px;
        a {
            span {
                margin: 0 auto;
            }
        }
    }
}
.new_time {
    width: 80px;
    height: 80px;
    background: #243b8a;
    padding-top: 20px;
    span {
        font-size: 14px;
        color: #fff;
        display: block;
        line-height: 20px;
        text-align: center;
    }
    p {
        font-size: 14px;
        color: #fff;
        line-height: 20px;
        display: block;
        text-align: center;
    }
}

.i_box6 {
    width: 100%;
    position: relative;
    .pic {
        img {
            position: relative;
            left: 50%;
            margin-left: -960px;
            width: 1920px;
            display: block;
        }
    }
    .box {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        .text {
            margin-left: 50%;
            margin-top: 12%;
            width: 50%;
            background: #fff;
            padding: 30px;
            padding-bottom: 0;
            .box_tit {
                span {
                    text-align: left;
                }
                p {
                    text-align: left;
                }
                em {
                    width: 50px;
                    height: 4px;
                    background: #243b8a;
                    display: block;
                    font-style: normal;
                    margin-top: 10px;
                }
            }
            .text1 {
                margin-top: 30px;
                p {
                    font-size: 14px;
                    color: #666;
                    display: block;
                    line-height: 180%;
                }
            }
        }
    }
    .more {
        margin-top: 30px;
        a {
            span {
                position: relative;
                top: 21px;
            }
        }
    }
}

.footer {
    padding-top: 60px;
    background: #252725;
    padding-bottom: 60px;
    width: 100%;
    overflow: hidden;
    .center {
        width: 1400px;
    }
    .box {
        h3 {
            font-size: 18px;
            color: #fff;
            display: block;
            font-weight: bold;
            margin-bottom: 25px;
        }
        .text1 {
            width: 36%;
            float: left;
            background: url(../img/fbg.png) no-repeat right top;
            p {
                padding-right: 35%;
                span {
                    font-size: 14px;
                    color: #999;
                    display: block;
                    padding-left: 30px;
                    line-height: 24px;
                    margin-bottom: 6px;
                    &:first-child {
                        background: url(../img/ic1.png) no-repeat left 3px;
                        background-size: 18px;
                    }
                    &:first-child +span {
                        background: url(../img/ic2.png) no-repeat left 3px;
                        background-size: 18px;
                    }
                    &:first-child +span +span{
                        background: url(../img/ic3.png) no-repeat left 3px;
                        background-size: 18px;
                    }
                    &:first-child +span +span +span {
                        background: url(../img/ic4.png) no-repeat left 3px;
                        background-size: 18px;
                    }
                }
            }
            .list {
                margin-top: 50px;
                a {
                    float: left;
                    font-size: 16px;
                    color: #999;
                    line-height: 100%;
                    border-left: 1px solid #999;
                    padding-right: 10px;
                    padding-left: 10px;
                    &:first-child {
                        border-left: none;
                        padding-left: 0;
                    }
                }
            }
        }
        .text2 {
            width: 39%;
            float: left;
            background: url(../img/fbg.png) no-repeat right top;
            padding: 0 7%;
            form {
                padding-left: 15px;
                input {
                    width: 100%;
                    height: 40px;
                    border: 1px solid #515251;
                    background: #252725;
                    margin-bottom: 8px;
                    padding: 0 5px;
                    outline: 0;
                }
                textarea {
                    width: 100%;
                    height: 70px;
                    border: 1px solid #515251;
                    background: #252725;
                    margin-bottom: 8px;
                    padding: 5px;
                    outline: 0;
                }
                .sub_btn {
                    background: #243158;
                    width: 100px;
                    font-size: 16px;
                    color: #fff;
                    text-align: center;
                    line-height: 40px;
                    border: none;
                }
            }
        }
        .text3 {
            width: 25%;
            float: left;
            h3 {
                text-align: center;
            }
            span {
                img {
                    background: #fff;
                    padding: 20px;
                    display: block;
                    margin: 0 auto;
                    border-radius: 5px;
                }
            }
            p {
                font-size: 14px;
                color: #fff;
                margin-top: 12px;
                text-align: center;
            }
        }
    }
}
.copyright {
    background: #252725;
    padding: 15px 0;
    border-top: 1px solid #515251;
    p {
        font-size: 14px;
        line-height: 24px;
        color: #999;
        text-align: center;
        display: block;
    }
}

.currson {
    background: #eee;
    p {
        background: url(../img/map.png) no-repeat left center;
        line-height: 40px;
        background-size: 18px;
        padding-left: 30px;
        a {
            font-size: 14px;
            color: #666;
            padding-right: 2px;
            &:hover {
                color: #659832;
            }
        }
    }
}
.pro_text {
    margin-bottom: 20px;
    .box {
        margin-top: 20px;
        .fl {
            width: 20%;
        }
        .fr {
            width: 77%;
        }
    }
}
.page_title {
    background: #243b8a;
    h3 {
        font-size: 24px;
        color: #fff;
        font-weight: 400;
        display: block;
        background: url(../img/pp.png) no-repeat left center;
        line-height: 80px;
        text-align: center;
    }
}
.page_tit {
    h3 {
        line-height: 50px;
        font-weight: normal;
        background: url(../img/le3.png) no-repeat 95% 15px #eee;
        background-size: 16px;
        width: 100%;
        float: left;
        margin-top: 10px;
        padding-left: 12px;
        a {
            float: left;
            font-size: 16px;
            color: #666;
        }
        &.on {
            background: url(../img/bottom.png) no-repeat 95% 15px #243b8a;
            background-size: 16px;
            a {
                color: #fff;
            }
        }
        &:hover {
            background: url(../img/bottom.png) no-repeat 95% 15px #243b8a;
            background-size: 16px;
            a {
                color: #fff;
            }
        }
    }
    ul {
        display: none;
        float: left;
        li {
            a {
                font-size: 13px;
                color: #666;
                display: block;
                line-height: 30px;
                padding-left: 20px;
                background: url(../img/bot.png) no-repeat left center;
                background-size: 12px;
                margin-left: 5px;
                &:hover {
                    color: #243b8a;
                    background: url(../img/bot2.png) no-repeat left center;
                    background-size: 12px;
                    display: block;
                    &:hover {
                        transition:.89s;
                    }
                }
            }
        }
    }
}

.prolist {
    li {
        width: 23%;
        margin-right: 2.66%;
        margin-bottom: 2.66%;
        float: left;
        border: 1px solid #ddd;
        padding: 12px;
        .pic {
            .imgauto {
                width: 100%;
                height: 235px;
                img {
                    transform: scale(1);
                    transition: .8s;
                }
            }
        }
        .text {
            i {
                font-size: 14px;
                color: #E53A3E;
                display: block;
                font-style: normal;
                margin-top: 15px;
            }
            a {
                font-size: 14px;
                color: #666;
                display: block;
                margin-top: 15px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }
        }
        &:nth-child(4n) {
            margin-right: 0;
        }
        &:hover {
            border-color: #243b8a;
            .text {
                a {
                    color: #243b8a;
                }
            }
            // .pic {
            //     .imgauto {
            //         img {
            //             transform: scale(1.1);
            //             transition: .8s;
            //         }
            //     }
            //     span {
            //         color: #659832;
            //     }
            // }
        }
    }
}

.pro_content {
    width: 100%;
    margin-bottom: 20px;
    .pro_pic {
        width: 50%;
        float: left;
    }
    .pro_text {
        width: 50%;
        float: left;
        padding-left: 2%;
        h3 {
            font-weight: normal;
            margin-top: 5px;
            line-height: 22px;
            font-size: 18px;
            color: #243b8a;
            padding-bottom: 10px;
            border-bottom: 1px dotted #ccc;
        }
        .text {
            width: 100%;
            margin-top: 12px;
            p {
                font-size: 14px;
                line-height: 24px;
                padding-bottom: 12px;
            }
            a {
                width: 100px;
                height: 32px;
                font-size: 14px;
                margin-top: 10px;
                display: block;
                color: #fff;
                line-height: 32px;
                background: url(../img/sanj.png) no-repeat 5px center #243b8a;
                background-size: 16px;
                padding-left: 24px;
                &:hover {
                    background: url(../img/sanj.png) no-repeat 5px center #243b8a;
                    background-size: 16px;
                }
            }
        }
    }
}

div.zoomDiv {
    z-index: 999;
    position: absolute;
    top: 0;
    left: 0;
    background: #ffffff;
    border: 1px solid #ddd;
    display: none;
    text-align: center;
    overflow: hidden;
    font-size: 0;
    img{
        max-width: none;
    }
}
div.zoomMask {
    position: absolute;
    background: url(../img/mask.png) repeat scroll 0 0 transparent;
    cursor: move;
    z-index: 1;
    max-width: 200px!important;
    max-height: 200px!important;
}

.img-center {
    text-align: center;
    font-size: 0;
    img {
        display: block;
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
        vertical-align: middle;
    }
}


.pc-slide {
    width: 100%;
    margin: 0 auto;
    .view {
        .swiper-slide {
            width: 100%;
            .imgauto {
                width: 100%;
                height: 350px;
                border: 1px solid #ddd;
                box-sizing:border-box;
            }
        }
    }
    .preview {
        width: 100%;
        margin-top: 10px;
        position: relative;
        .swiper-container {
            width: 80%;
            margin: 0 10%;
        }
        .swiper-slide {
            width: 25%;
            padding: 5px;
            .imgauto {
                width: 100%;
                height: 80px;
                border: 1px solid #ddd;
                img {
                    display: block;
                    padding: 5px;
                }
            }
        }
        .active-nav {
            .imgauto {
                border: 1px solid #243b8a;
            }
        }
        .arrow-left {
            background: url(../img/le2.png) no-repeat left top;
            background-size: 100%;
            position: absolute;
            left: 10px;
            top: 50%;
            margin-top: -9px;
            width: 9px;
            height: 18px;
            z-index: 10;
        }
        .arrow-right {
            background: url(../img/ri2.png) no-repeat left bottom;
            background-size: 100%;
            position: absolute;
            right: 10px;
            top: 50%;
            margin-top: -9px;
            width: 9px;
            height: 18px;
            z-index: 10;
        }
    }
}

.show_title {
    border-bottom: 1px solid #eee;
    margin-top: 40px;
    margin-bottom: 20px;
    h3 {
        width: 120px;
        height: 42px;
        font-size: 14px;
        margin-top: 10px;
        display: block;
        color: #fff;
        line-height: 42px;
        padding-left: 24px;
        background: #243b8a;
    }
}
.content_tit {
    h3 {
        font-size: 22px;
        color: #222;
        display: block;
        margin: 20px 0;
        font-weight: normal;
        text-align: center;
    }
}
.content {
    width: 100%;
    p {
        font-size: 16px;
        line-height: 32px;
        margin-bottom: 15px;
        color: #666;
    }
    img {
        display: block;
        max-width: 100%;
        margin: 20px auto;
    }
}

.show_pages {
    margin-top: 30px;
    p {
        font-size: 16px;
        color: #666;
        display: block;
        line-height: 24px;
        margin-top: 8px;
        &:hover {
            a {
                color: #243b8a;
            }
        }
    }
}

.pa_title {
    border-bottom: 1px dashed #ccc;
    margin-bottom: 10px;
    h3 {
        font-size: 18px;
        color: #666;
        display: block;
        line-height: 42px;
        font-weight: normal;
    }
}
.artlist {
    li {
        width: 100%;
        padding: 20px;
        background: #fff;
        margin-bottom: 23px;
        position: relative;
        box-shadow: 0px 1px 10px 1px #ccc;
        .libg {
            height: 3px;
            background: #243b8a;
            width: 0;
            transition: .8s;
            position: absolute;
            bottom: 0;
            left: 0;
        }
        .item {
            position: relative;
            .new_time {
                position: absolute;
                left: 0;
                top: 0;
            }
            .text {
                width: 100%;
                min-height: 80px;
                padding-left: 100px;
                a {
                    font-size: 18px;
                    color: #333;
                    font-weight: bold;
                    display: block;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                }
                p {
                    font-size: 16px;
                    color: #999;
                    display: block;
                    line-height: 24px;
                    margin-top: 5px;
                }
            }
        }
        &:hover {
            .libg {
                width: 100%;
                transition: .8s;
            }
        }
    }
}

.case_list {
    li {
        width: 32%;
        margin-right: 2%;
        margin-bottom: 2%;
        float: left;
        background: #fff;
        box-shadow: 0 1px 10px 1px #ccc;
        .pic {
            .imgauto {
                width: 100%;
                height: 195px;
                img {
                    transform: scale(1);
                    transition: .8s;
                }
            }
        }
        .text {
            padding: 12px 10px;
            a {
                font-size: 16px;
                color: #666;
                display: block;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            p {
                font-size: 14px;
                color: #999;
                margin-top: 5px;
            }
        }
        .libg {
            margin-top: 12px;
            position: relative;
            i {
                width: 0;
                height: 2px;
                background: #243b8a;
                position: absolute;
                left: 0;
                top: 0;
                z-index: 3;
            }
            span {
                width: 100%;
                height: 1px;
                background: #ddd;
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                z-index: 2;
            }
        }
        .time {
            padding: 12px 10px;
            padding-top: 0;
            span {
                color: #999;
                font-size: 14px;
                display: block;
            }
        }
        &:nth-child(3n) {
            margin-right: 0;
        }
        &:hover {
            .libg {
                margin-top: 12px;
                position: relative;
                i {
                    width: 100%;
                    transition: .8s;
                }
                span {
                    
                }
            }
        }
    }
}


.pagepro_tit {
    border-bottom: 1px solid #ddd;
    margin-bottom: 20px;
    padding-bottom: 10px;
    .protitle {
        float: left;
        h3 {
            font-size: 18px;
            line-height: 100%;
            color: #243b8a;
            padding-top: 15px;
        }
    }
}
.page_search {
    float: right;
    .put1 {
        width: 182px;
        height: 36px;
        line-height: 36px;
        border: 1px solid #e6e6e6;
        border-right: 0;
        float: left;
        padding: 0 10px;
        float: left;
        outline: 0;
    }
    .btn_submit {
        width: 78px;
        float: right;
        height: 36px;
        outline: 0;
        line-height: 36px;
        text-align: center;
        border: none;
        color: #fff;
        background-color: #243b8a;
        transition:  .2s;
        float: left;
    }
}























































.about_main {
    .box_tit {
        span {
            color: #666;
        }
        p {
            color: #888;
            margin-top: 0px;
        }
        small {
            width: 50px;
            height: 5px;
            margin: 0 auto;
            display: block;
            background: #659832;
            margin-top: 10px;
        }
    }
} 
.about_text1 {
    .box {
        width: 100%;
        .fl {
            width: 48%;
            margin-right: 2%;
            background: #fafafa;
            padding: 22px;
            p {
                font-size: 14px;
                line-height: 27px;
                display: block;
                color: #666;
            }
        }
        .fr {
            width: 48%;
            margin-left: 2%;
            img {
                max-width: 100%;
                display: block;
            }
        }
    }
}
.about_text2 {
    background: #f3f4f9;
    padding-top: 50px;
    margin-top: 50px;
    .box {
        .fl {
            width: 48%;
            margin-right: 2%;
            img {
                display: block;
                max-width: 100%;
            }
        }
        .fr {
            width: 48%;
            margin: 0 1%;
            padding-left: 5%;
            .box_tit {
                span {
                    text-align: left;
                    color: #666;
                }
                p {
                    text-align: left;
                    color: #888;
                }
                small {
                    margin: 0;
                    margin-top: 10px;
                }
            }
            .list {
                li {
                    width: 100%;
                    position: relative;
                    padding-left: 50px;
                    margin-bottom: 45px;
                    img {
                        position: absolute;
                        left: 0;
                        top: 5px;
                        width: 50px;
                        display: block;
                    }
                    p {
                        display: block;
                        padding-left: 20px;
                        span {
                            font-size: 20px;
                            color: #666;
                            font-weight: bold;
                            display: block;
                        }
                        i {
                            font-style: normal;
                            font-size: 14px;
                            color: #888;
                            display: block;
                            margin-top: 12px;
                        }
                    }
                }
            }
        }
    }
}
.about_text3 {
    .box {
        figure {
            width: 23%;
            margin: 0;
            margin-right: 2.66%;
            margin-bottom: 2.66%;
            float: left;
            position: relative;
            .img-dv {
                width: 100%;
                a {
                    img {
                        width: 100%;
                    }
                }
            }
            span {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                display: block;
                opacity: 0;
                overflow: hidden;
                z-index: 10;
                transition: .8s;
                cursor: pointer;
                background: url(../img/ss.png) no-repeat center rgba(0,0,0,.5);
                background-size: auto 15%;
            }
            &:nth-child(4n) {
                margin-right: 0;
            }
            &:hover {
                span {
                    opacity: 1;
                    transition: .8s;
                }
            }
        }
    }
}
.about_text4 {
    background: #f3f4f9;
    padding: 50px 0;
    margin-top: 50px;
    .box {
        li {
            width: 20%;
            float: left;
            position: relative;
            padding-left: 50px;
            img {
                position: absolute;
                left: 0;
                top: 5px;
                width: 50px;
                display: block;
            }
            p {
                display: block;
                padding-left: 15px;
                span {
                    font-size: 20px;
                    color: #666;
                    display: block;
                    margin-top: 5px;
                }
                i {
                    font-style: normal;
                    font-size: 14px;
                    color: #888;
                    display: block;
                    margin-top: 5px;
                }
            }
        }
    }
}
.about_text5 {
    .box {
        margin: 50px 0;
        h3 {
            font-size: 18px;
            color: #666;
            display: block;
            display: block;
            font-weight: normal;
            margin-bottom: 26px;
        }
        .text1 {
            width: 25%;
            float: left;
            ul {
                li {
                    width: 100%;
                    float: left;
                    position: relative;
                    padding-left: 22px;
                    margin-bottom: 20px;
                    img {
                        position: absolute;
                        left: 0;
                        top: 5px;
                        width: 21px;
                        display: block;
                    }
                    p {
                        display: block;
                        padding-left: 12px;
                        span {
                            font-size: 18px;
                            color: #666;
                            display: block;
                        }
                        i {
                            font-style: normal;
                            font-size: 14px;
                            color: #888;
                            display: block;
                            margin-top: 5px;
                        }
                    }
                }
            }
        }
        .text2 {
            width: 10%;
            float: left;
            margin-left: 8%;
            a {
                font-size: 14px;
                color: #666;
                display: block;
                line-height: 20px;
                margin-bottom: 17px;
                padding-left: 18px;
                background: url(../img/le3.png) no-repeat left center;
                background-size: 12px;
                &:hover {
                    color: #659832;
                    text-decoration: underline;
                }
            }
        }
        .text3 {
            width: 10%;
            float: left;
            margin-left: 5%;
            a {
                font-size: 14px;
                color: #666;
                display: block;
                line-height: 20px;
                margin-bottom: 17px;
                &:hover {
                    color: #659832;
                    text-decoration: underline;
                }
            }
        }
        .text4 {
            width: 35%;
            float: left;
            margin-left: 7%;
            ul {
                li {
                    position: relative;
                    padding-left: 60px;
                    min-height: 60px;
                    margin-bottom: 20px;
                    .pic {
                        width: 60px;
                        height: 60px;
                        position: absolute;
                        left: 0;
                        top: 0;
                        img {
                            width: 100%;
                            display: block;
                            border-radius: 50%;
                        }
                    }
                    .text {
                        padding-left: 12px;
                        padding-top: 5px;
                        p {
                            font-size: 14px;
                            color: #888;
                            display: block;
                            padding-left: 30px;
                            background: url(../img/rij.png) no-repeat left center;

                        }
                        a {
                            display: block;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            overflow: hidden;
                            font-size: 14px;
                            color: #666;
                            margin-top: 8px;
                            &:hover {
                                color: #659832;
                            }
                        }
                    }
                }
            }
        }
    }
}
.contact_text1 {
    .box {
        li {
            width: 25%;
            float: left;
            border-left: 1px solid #eee;
            i {
                text-align: center;
                line-height: 50px;
                font-style: normal;
                display: block;
                img {
                    display: inline-block;
                    vertical-align: middle;
                }
            }
            span {
                display: block;
                text-align: center;
                font-size: 18px;
                color: #666;
                margin-top: 10px;
            }
            p {
                font-size: 14px;
                color: #888;
                margin-top: 5px;
                text-align: center;
            }
            &:first-child {
                border: none;
            }
        }
    }
}


.art2list {
    .i_box8 {
        background: none;
        margin-top: 0;
        .box {
            li {
                margin: 0;
                margin-bottom: 50px;
                margin-right: 1.33%;
                &:first-child {
                    margin-left: 0;
                }
                &:nth-child(4n) {
                    margin-right: 0;
                }
            }
        }
    }
}










.iet {
    height: 30px;
    width: 100%;
    background: #fff3d7;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    display: none;
    p {
        width: 100%;
        text-align: center;
        font-size: 12px;
        color: #333;
        line-height: 30px;
        a {
            color: #3aba50;
            font-size: 12px;
        }
    }
    span {
        position: absolute;
        right: 5%;
        top: 0;
        height: 30px;
        line-height: 30px;
        font-size: 12px;
        color: #333;
        cursor: pointer;
        i {
            font-size: 12px;
            color: #333;
            padding-left: 5px;
            font-style: normal;
        }
    }
}



@media screen and (max-width: 1400px){
    .i_box2 .center{
        width: 100%;
    }
    .i_box3 .center {
        width: 100%;
    }
    .i_box5 .center {
        width: 100%;
    }
    .footer .center {
        width: 100%;
    }
    .i_box4 .box4list .swiper-slide {
        width: 80%;
    }
    .i_box4 .box4list .swiper-slide .text p {
        line-height: 30px;
        height: 120px;
        overflow: hidden;
    }
    
}

@media screen and (max-width: 1200px){
    .center {
        width: 100%;
    }
    .i_box4 .box4list .swiper-slide .text p {
        height: 90px;
    }
}

@media screen and (max-width: 1020px){
    .header {
        display: none;
    }
    .m_header {
        display: block;
    }
    .m_banner {
        display: block;
    }
    .pc_banner {
        display: none;
    }
    .m_banner {
        display: block;
    }
    .footer .box .text1 .list {
        display: none;
    }
}

@media screen and (max-width: 767px){
    .i_box1_list {
        display: none;
    }
    .i_box1_list_m {
        display: block;
    }
    .i_box1 {
        padding-top: 20px;
    }
    .i_box2 {
        padding-top: 20px;
    }
    .i_box2 .box {
        margin-top: 0;
        width: 96%;
        margin: 0 2%;
    }
    .i_box2 .box .fl {
        width: 100%;
        padding-top: 0;
    }
    .i_box2 .box .fr {
        width: 100%;
        padding-top: 0;
        margin-top: 20px;
    }
    .i_box3 .box .box3list .prev {
        width: 35px;
        height: 70px;
    }
    .i_box3 .center {
        padding: 0 40px;
    }
    .i_box3 .box .box3list .next {
        width: 35px;
        height: 70px;
    }
    .i_box3 {
        padding: 30px 0;
    }
    .i_box3 .box_tit p {
        padding: 0 10%;
        line-height: 150%;
    }
    .i_box3 .box {
        margin-top: 30px;
    }
    .i_box4 {
        padding: 20px 0;
    }
    .i_box4 .box_tit p {
        padding: 0 10%;
        line-height: 150%;
    }
    .i_box4 .box4list .swiper-slide .text {
        position: relative;
        width: 100%;
        padding: 15px;
        padding-bottom: 60px;
    }
    .i_box4 .box4list .prev {
        bottom: 10px;
        left: 30%;
    }
    .i_box4 .box4list .next {
        bottom: 10px;
        left: auto;
        right: 30%;
    }
    .i_box5 {
        padding: 20px 0;
    }
    .i_box5 .box {
        width: 96%;
        margin: 0 2%;
        margin-top: 20px;
    }
    .i_box5 .box .fl {
        width: 100%;
    }
    .i_box5 .box .fr {
        width: 100%;
        margin-top: 20px;
    }
    .i_box6 .pic img {
        width: 1200px;
        margin-left: -600px;
    }
    .i_box6 .box .text {
        width: 85%;
        margin-left: 7.5%;
        padding: 15px;
    }
    .i_box6 .box .text .text1 {
        margin-top: 15px;
    }
    .i_box6 .more {
        margin-top: 0;
    }
    .footer .box .text1 {
        width: 100%;
        background: none;
    }
    .footer .box .text2 {
        width: 100%;
        background: none;
    }
    .footer .box .text3 {
        display: none;
    }
    .footer {
        padding: 30px 2%;
    }
    .pro_text .box .fl {
        width: 96%;
        margin: 2%;
    }
    .pro_text .box .fr {
        width: 96%;
        margin: 2%;
    }
    .pro_content .pro_pic {
        width: 100%;
    }
    .pro_content .pro_text {
        width: 100%;
        margin-top: 12px;
    }
    div.zoomMask {
        display: none!important;
    }

}

@media screen and (max-width: 450px) {
    #certify .swiper-slide img {
        padding: 5px;
        background: #fff;
    }
    .box_tit span {
        font-size: .18rem;
    }
    .box_tit p {
        font-size: .12rem;
    }
    .i_box1 .box {
        margin-top: 30px;
    }
    .i_box2 .box_tit span {
        text-align: center;
    }
    .i_box2 .box_tit p {
        text-align: center;
        padding: 0 10%;
        line-height: 160%;
    }
    .i_box2 .box_tit em {
        margin: 0 auto;
        margin-top: 10px;
    }
    .i_box2 .box .fl .text {
        margin-top: 20px;
        padding: 0 2%;
    }
    .i_box2 .box .fl .text p {
        font-size: .11rem;
    }
    .i_box2 .box .fl .text .more {
        margin-top: 20px;
    }
    .more a span {
        width: 1rem;
        height: .28rem;
        font-size: .11rem;
        border-radius: 3px;
        line-height: .28rem;
    }
    .i_box2 .box .fr {
        padding: 0 2%;
    }
    .i_box2 {
        background: none;
        padding-bottom: 20px;
    }
    .i_box3 .center {
        padding: 0 .58rem;
    }
    .i_box3 .box .box3list .swiper-slide {
        padding: 0;
    }
    .i_box3 .box .box3list .swiper-slide .text {
        width: 260px;
        margin: 0 auto;
    }
    .i_box3 .box .box3list .next {
        background: url(../img/pri.png) no-repeat center #243b8a;
    }
    .i_box3 .box .box3list .prev{
        background: url(../img/ple.png) no-repeat center #243b8a;
    }
    .i_box4 .box4list .prev {
        width: .7rem;
        bottom: 10px;
        left: 25%;
        background: url(../img/le1.png) no-repeat center rgba(36, 59, 138,1);
        background-size: 100%;
    }
    .i_box4 .box4list .next {
        width: .7rem;
        bottom: 10px;
        left: auto;
        right: 25%;
        background: url(../img/ri1.png) no-repeat center rgba(36, 59, 138,1);
        background-size: 100%;
    }
    .i_box4 .box4list .swiper-slide .text a {
        font-size: .13rem;
    }
    .i_box4 .box4list .swiper-slide .text p {
        font-size: .11rem;
        line-height: .2rem;
        height: .6rem;
        margin-top: 10px;
    }
    .i_box4 .box4list {
        margin-top: 20px;
    }
    .i_box4 .more {
        margin-top: 20px;
    }
    .new_time {
        width: 70px;
        height: 70px;
        padding-top: 15px;
    }
    .i_box5 .box .fl .new_time {
        margin-top: -35px;
    }
    .i_box5 .box .fl .text {
        padding: 12px 15px;
    }
    .i_box5 .box .fl .text a {
        font-size: .12rem;
    }
    .i_box5 .box .fl .text p {
        font-size: .11rem;
        line-height: 160%;
        margin-top: 10px;
    }
    .i_box5 .box .fr li {
        padding: 12px 15px;
    }
    .i_box5 .box .fr li .item .text {
        padding-left: 85px;
        min-height: 70px;
    }
    .i_box5 .box .fr li .item .text a {
        font-size: .12rem;
    }
    .i_box5 .box .fr li .item .text p {
        font-size: .11rem;
        line-height: .18rem;
        height: .36rem;
        overflow: hidden;
    }
    .i_box5 .more {
        margin-top: 10px;
    }
    .i_box6 .more a span {
        top: .25rem;
    }
    .i_box6 .box .text .text1 p {
        font-size: .11rem;
        line-height: 160%;
    }
    .footer .box .text3 h3 {
        display: none;
    }
    .footer .box .text2 {
        padding: 0;
        margin-top: 20px;
    }
    .footer .box .text2 form {
        padding: 0;
    }
    .footer .box h3 {
        font-size: .13rem;
        color: #999;
        margin-bottom: 12px;
    }
    .footer .box .text2 h3 {
        display: none;
    }
    .footer .box .text1 p {
        padding-right: 0;
    }
    .footer .box .text2 form .sub_btn {
        color: #999;
    }
    .copyright {
        padding: 15px 5%;
    }
    .page_title h3 {
        line-height: .5rem;
        font-size: .16rem;
        background-size: .2rem;
    }
    .page_tit h3 {
        line-height: 42px;
    }
    .page_tit h3 a {
        font-size: .115rem;
    }
    .pro_text .box {
        margin-top: 10px;
    }
    .currson {
        padding: 0 2%;
    }
    .prolist li {
        width: 48%;
        margin: 1% 0;
    }
    .prolist li:nth-child(2n) {
        margin-left: 4%;
    }
    .prolist li .pic .imgauto {
        height: 1.8rem;
    }
    .show_title {
        margin-top: 20px;
    }
    .content p {
        font-size: .115rem;
        line-height: 200%;
    }
    .show_pages p {
        font-size: .115rem;
    }
    .artlist li {
        padding: 10px;
    }
    .artlist li .item .text {
        padding-left: 85px;
        min-height: 70px;
    }
    .artlist li .item .text a {
        font-size: .12rem;
    }
    .artlist li .item .text p {
        font-size: .11rem;
        line-height: .18rem;
        height: .36rem;
        overflow: hidden;
    }
    .case_list li {
        width: 48%;
        margin-right: 4%;
        background: #fff;
        box-shadow: 0 1px 10px 1px #ccc;
    }
    .case_list li:nth-child(3n) {
        margin-right: 4%;
    }
    .case_list li:nth-child(2n) {
        margin-right: 0;
    }
    .case_list li .pic {
        padding: 5px;
    }
    .case_list li .pic .imgauto {
        height: .94rem;
    }
    .case_list li .text p {
        font-size: .11rem;
        line-height: .18rem;
        height: .36rem;
        overflow: hidden;
    }
    .case_list li .text {
        padding: 5px 8px;
    }
    .case_list li .text a {
        font-size: .12rem;
    }
    .case_list li .time {
        padding-bottom: 5px;
    }
    .case_list li .time span {
        font-size: .11rem;
        margin-top: 5px;
    }
    .pagepro_tit .protitle {
        display: none;
    }
    .pagepro_tit {
        border: none;
        margin-bottom: 5px;
    }
    .page_search {
        width: 100%;
    }
    .page_search .put1 {
        width: 75%;
    }
    .page_search .btn_submit {
        width: 25%;
    }
}










@media screen and (max-width: 375px) {
    .i_box3 .center {
        padding: 0 57px;
    }
} 
@media screen and (max-width: 320px) {
    .i_box3 .center {
        padding: 0 30px;
    }
} 

/****************************** media ******************************/